import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  init() {
    console.log('home')
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
}
