const DEV = process.env.NODE_ENV === 'development'

import Swiper from 'swiper'
import { Grid, Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/grid'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import lightGallery from 'lightgallery'
import lgVideo from 'lightgallery/plugins/video'
import 'lightgallery/css/lightgallery-bundle.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init common')

    var intro = document.getElementById('intro')
    if (intro) {
      document.body.classList.add('overflow-hidden')
      intro.classList.add('animated')
      setTimeout(() => {
        document.body.classList.remove('overflow-hidden')
        var event = new CustomEvent('startAos')
        document.dispatchEvent(event)
      }, 4000)
    }

    const video_container = document.querySelector(
      '#hp_video_animation_container'
    )
    const video = document.querySelector('#hp_video_animation')

    if (video_container) {
      let videoTl = gsap.timeline({
        scrollTrigger: {
          trigger: video_container,
          pin: true,
          //markers: true,
          start: 'top top',
          end: 'bottom+=80vh',
          scrub: true,
        },
      })

      videoTl.to(video, {
        width: '100%',
        aspectRatio: '16/9',
        borderTopRightRadius: '3.125rem',
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
        borderTopLeftRadius: '0',
      })
    }

    // Remove no-js
    document.documentElement.classList.remove('no-js')
    document.documentElement.classList.add('js')

    // Test touch
    if (!('ontouchstart' in document.documentElement)) {
      document.documentElement.classList.add('no-touch')
    } else {
      document.documentElement.classList.add('touch')
    }

    const menu_btn = document.querySelectorAll('.menu_btn')

    menu_btn.forEach((item, i) => {
      item.addEventListener('click', function (e) {
        document.body.classList.toggle('show_menu')
      })
    })

    const loader = document.getElementById('loader')

    function hideLoader() {
      loader.classList.add('hide')
    }

    function showLoader() {
      loader.classList.add('show')
    }

    if (!DEV) {
      showLoader()
      setTimeout(function () {
        hideLoader()
      }, 2000)

      window.addEventListener('load', function () {
        hideLoader()
      })
    }

    //Animazioni
    AOS.init({
      startEvent: 'startAos',
    })

    if (!document.getElementById('intro')) {
      var event = new CustomEvent('startAos')
      document.dispatchEvent(event)
    }

    //Header fixed
    window.onscroll = function (e) {
      if (window.scrollY > 100) {
        document.body.classList.add('fixed_header')
      } else {
        document.body.classList.remove('fixed_header')
      }
    }

    //Menu mobile
    document.querySelectorAll('.menu-item-has-children').forEach((item, i) => {
      item.addEventListener('click', function (e) {
        this.classList.toggle('open')
      })
    })
    document
      .querySelectorAll('.menu-item-has-children a')
      .forEach((item, i) => {
        item.addEventListener('click', function (e) {
          e.stopPropagation()
        })
      })

    //Youtube lightbox
    document.querySelectorAll('.js-video-lightbox').forEach((item, i) => {
      item.id = 'gallery-' + i
      lightGallery(document.getElementById('gallery-' + i), {
        selector: 'this',
        plugins: [lgVideo],
        download: false,
        counter: false,
        youTubePlayerParams: {
          rel: 0,
        },
      })
    })

    //Accordion
    const accordions = document.querySelectorAll('.js-toggle-accordion')
    if (accordions) {
      accordions.forEach((item, i) => {
        item.addEventListener('click', function (e) {
          this.classList.toggle('open')
          var tgId = this.dataset.target
          if (tgId) {
            var panel = document.getElementById(tgId)
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null
            } else {
              panel.style.maxHeight = panel.scrollHeight + 'px'
            }
          }
        })
      })
    }

    //Toggle class
    const toggleClassBtns = document.querySelectorAll('.js-toggle-class')
    if (toggleClassBtns) {
      toggleClassBtns.forEach((item, i) => {
        item.addEventListener('click', function (e) {
          this.classList.toggle('active')
          var tgId = this.dataset.target
          var tgGroup = this.dataset.targetGroup
          var panel = document.getElementById(tgId)
          var classname = this.dataset.classname
          if (tgGroup) {
            document.querySelectorAll(tgGroup).forEach((item, i) => {
              if (item.id != tgId) {
                item.classList.remove(classname)
              }
            })
          }
          if (panel && classname) {
            panel.classList.toggle(classname)
          }
        })
      })
    }

    //Swiper gallery
    new Swiper('.swiper_gallery', {
      modules: [Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.gallery_pagination',
        clickable: true,
      },
    })

    //Swiper servizi
    new Swiper('.services_slider', {
      modules: [Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.services_pagination',
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 'auto',
          spaceBetween: 20,
        },
      },
    })

    //Swiper cards
    new Swiper('.cards_slider', {
      modules: [Grid, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.cards_pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          grid: {
            rows: 2,
            fill: 'row',
          },
        },
      },
    })

    //Swiper news (HP)
    new Swiper('.news_swiper', {
      modules: [Pagination, Autoplay, Navigation],
      speed: 600,
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,
      navigation: {
        nextEl: '.news_next',
        prevEl: '.news_prev',
      },
      pagination: {
        el: '.news_pagination',
        clickable: true,
      },
      autoplay: {
        delay: 3000,
      },
    })

    //Swiper MOBILE (solo mobile, grid su desktop)
    new Swiper('.mobile_swiper', {
      modules: [Pagination],
      slidesPerView: 'auto',
      spaceBetween: 20,
      pagination: {
        el: '.mobile_pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    })

    //Swiper loghi
    new Swiper('.swiper_loghi', {
      modules: [Pagination, Autoplay],
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
      autoplay: {
        delay: 3000,
      },
      loop: true,
      pagination: {
        el: '.loghi_pagination',
        clickable: true,
      },
    })

    //News filter
    jQuery(document).ready(function ($) {
      let filterBtns = $('.js-filter-news'),
        loadMoreBtn = $('#loadMoreBtn'),
        news = $('#newsGrid'),
        numMax = $('#numOfPosts').val() || 6,
        paged = 1

      filterBtns.bind('click', function (e) {
        e.preventDefault()
        paged = 1
        var $this = $(this)
        filterBtns.removeClass('active')
        var catID = $this.attr('data-cat')
        loadPosts(catID)
        $this.addClass('active')
      })

      loadMoreBtn.bind('click', function (e) {
        e.preventDefault()
        paged++
        let filter = $('.js-filter-news.active')
        var catID = filter.attr('data-cat')
        loadPosts(catID)
      })

      function loadPosts(catID) {
        news.addClass('loading')

        $.ajax({
          type: 'POST',
          url: '/wp-admin/admin-ajax.php',
          dataType: 'json',
          data: {
            action: 'news_filter',
            categoryID: catID,
            postsPerPage: numMax,
            paged: paged,
          },
          success: function (res) {
            if (paged == 1) {
              news.html(res.posts)
            } else {
              news.append(res.posts)
            }
            if (res.maxPages > paged && loadMoreBtn.length) {
              loadMoreBtn.show()
            } else {
              loadMoreBtn.hide()
            }
            news.removeClass('loading')
          },
          fail: function (err) {
            console.log('There was an error: ' + err)
            news.removeClass('loading')
          },
        })
      }
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    console.log('finalize common')
  },
}
