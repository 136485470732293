export default class PwaBanner {
  constructor() {
    this.installPrompt = null
    this.isIos =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    this.isAndroid = /Android/.test(navigator.userAgent)
    this.dismissButton = document.querySelectorAll('.pwa_banner_dismiss')
    this.showBanner =
      localStorage.getItem('pwa_banner_dismissed') === null || //check when the banner was dismissed
      localStorage.getItem('pwa_banner_dismissed') <
        new Date().getTime() - 86400000 * 30 //check if the banner was dismissed in the last 30 days
    this.installAndroid = document.querySelector('#install_android')
  }

  init() {
    //if #pwa_android_banner or #pwa_ios_banner is not present, stop the script
    if (!document.querySelector('#pwa_android_banner')) {
      return
    }

    if (!document.querySelector('#pwa_ios_banner')) {
      return
    }

    if (!this.showBanner) {
      return
    }

    //check

    console.log(`isIos: ${this.isIos}`)
    console.log(`isAndroid: ${this.isAndroid}`)

    if (this.isIos) {
      //check if the PWA is installed
      if (window.navigator.standalone) {
        return
      }

      this.showInstallIos()
    }

    if (this.isAndroid) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.installPrompt = e
        this.showInstallAndroid()
      })
    }

    //if user click on #pwa_banner_dismiss button, hide the banner and store the dismissed state in localStorage
    this.dismissButton.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault()
        //set hidden class to parent and store dismissed state in localStorage
        button.parentNode.classList.add('hidden')
        const currentTime = new Date().getTime()
        localStorage.setItem('pwa_banner_dismissed', currentTime)
        this.showBanner = false
      })
    })

    //listen for beforeinstallprompt event

    //listen for appinstalled event
    window.addEventListener('appinstalled', (evt) => {
      console.log('a2hs installed')
    })
  }

  showInstallIos() {
    const banner = document.querySelector('#pwa_ios_banner')
    banner.classList.remove('hidden')
  }

  showInstallAndroid() {
    const banner = document.querySelector('#pwa_android_banner')
    banner.classList.remove('hidden')
    this.installAndroid.addEventListener('click', (e) => {
      e.preventDefault()
      this.installPrompt.prompt()
      this.installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt')
          banner.classList.add('hidden')
        } else {
          console.log('User dismissed the install prompt')
        }
      })
    })
  }
}
